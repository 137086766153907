import React, {useEffect, useState} from "react";
import {Card, Checkbox, Col, Collapse, Row, message} from "antd";
import api from "../../api";
import {getLabel} from "../additional-data/labelMapper";

const {Panel} = Collapse;

export function ColumnsPreferences() {

    const [tables, setTables] = useState([])
    const [defaultColumns, setDefaultColumns] = useState({})
    const [overridenColumns, setOverridenColumns] = useState({})

    useEffect(() => {
        api.get('/settings/available-tables')
            .then(json => setTables(json.data))
    }, [])

    const getDefaultColumns = tableName => {
        api.get(`/settings/table-default?name=${tableName}`)
            .then(json => setDefaultColumns({...defaultColumns, [tableName]: json.data.columns}))
    }

    const getOverridenColumns = tableName => {
        api.get(`/settings/table-overriden?name=${tableName}`)
            .then(json => setOverridenColumns({...overridenColumns, [tableName]: json.data.columns}))
            .catch(error => setOverridenColumns({...overridenColumns, [tableName]: null}))
    }

    const getChecked = tableName => {
        if (overridenColumns[tableName]) {
            return overridenColumns[tableName]
        } else if (defaultColumns[tableName]) {
            return defaultColumns[tableName]
        }
    }

    useEffect(() => {
        for (let table of tables) {
            getDefaultColumns(table.name)
            getOverridenColumns(table.name)
        }
    }, [tables])

    const onChange = (checked, tableName) => {
        api.post(`/settings/override-columns?name=${tableName}`, {columns: [...checked, 'label']})
            .then(json => {
                message.success('Updated')
                setOverridenColumns({...overridenColumns, [tableName]: json.data.columns})
            })
    }

    return <Row>
        <Col span={24} style={{padding: 10}}>
            <Card>
                <Collapse defaultActiveKey={"1"}>
                    {tables && tables.map((v, i) => {
                        return <Panel key={(i + 1).toString()} header={v.name}>
                            <Checkbox.Group
                                value={getChecked(v.name)}
                                style={{
                                    width: '100%',
                                }}
                                onChange={checked => onChange(checked, v.name)}
                            >
                                <Row>
                                    {defaultColumns[v.name] && defaultColumns[v.name].map(col => {
                                        if (col !== 'label') {
                                            return <Col span={8}>
                                                <Checkbox value={col}>{getLabel(col)}</Checkbox>
                                            </Col>
                                        } else return null;
                                    })}
                                </Row>
                            </Checkbox.Group>
                        </Panel>
                    })}
                </Collapse>
            </Card>
        </Col>
    </Row>

}
