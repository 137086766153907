import React, {useEffect, useState} from 'react'
import {Tabs} from "antd";
import {StructuredData} from "./StructuredData";
import {BomDataTab} from "./BomDataTab";
import {properties, showAnalyticsTabs} from "../../properties";
import {AttachedDocuments} from "./AttachedDocuments";
import {MaintenanceWork} from "./MaintenanceWork";

const { TabPane } = Tabs;

export function EquipmentInfoTabs ({informationData, bomData, maint_works = [], documentsList=[],
                                       updateDocsTable, item}) {
    const [activeKey, setActiveKey] = useState("2")

    useEffect(() => {
        if (showAnalyticsTabs) {
            setActiveKey("1")
        }
    },[informationData])

    return  <Tabs defaultActiveKey={"2"} activeKey={activeKey} onTabClick={setActiveKey}>
        {showAnalyticsTabs && <TabPane tab={'Information'} key={"1"}>
            <StructuredData structured={informationData}/>
        </TabPane>}
        <TabPane tab={`Bill of Material  (${bomData.length || 0})`} key={"2"}>
            <BomDataTab equipment={bomData} />
        </TabPane>
        <TabPane tab={`Maintenance Works (${maint_works.length || 0})`} key={"3"}>
            <MaintenanceWork data={maint_works} />
        </TabPane>
        {properties.showProposedBomTab && <TabPane tab={<a href={`/proposed?q=${item.material}`} target="_blank"
                                                           rel="noopener noreferrer">Proposed BOM</a>} key={null} />}
        <TabPane tab={`Attached Documents (${documentsList.length || 0})`} key={'9'}>
            <AttachedDocuments item={item} documentsList={documentsList} updateDocsTable={updateDocsTable} />
        </TabPane>

    </Tabs>

}
